<template>
    <div class="app-container">
        <Box :type="'info'" :active="true" style="margin-top: 0">
            <div slot="box-body">
                <ul class="rawUl">
                    <li
                        v-html="'Documentul incarcat trebuie sa contina fiecare pagina semnata in dreptul chenarului.'"
                    ></li>
                    <li v-html="'Dimnesiunea maxima pentru fisierele incarcate: <b>5 MB</b>.'"></li>
                </ul>
            </div>
        </Box>
        <div class="docs-container">
            <div class="legend">
                <div class="legend-item">
                    <span class="bullet info"></span>
                    <span class="text">{{ $t('account.docs.docs-22') }}</span>
                </div>
            </div>
            <el-row :gutter="20" class="docs" v-if="docs.length > 0">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" v-for="(doc, i) in infoDocs" :key="i">
                    <doc :data="doc" @reportDoc="openReportDialog" @fileUpload="getDocuments"></doc>
                </el-col>
            </el-row>
            <div class="legend">
                <div class="legend-item">
                    <span class="bullet unsigned"></span>
                    <span class="text">{{ $t('account.docs.docs-23') }}</span>
                </div>
            </div>
            <el-row :gutter="20" class="docs" v-if="docs.length > 0">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" v-for="(doc, i) in unsignedDocs" :key="i">
                    <doc :data="doc" @reportDoc="openReportDialog" @fileUpload="getDocuments"></doc>
                </el-col>
            </el-row>
            <div class="legend">
                <div class="legend-item">
                    <span class="bullet waiting"></span>
                    <span class="text">{{ $t('account.docs.docs-24') }}</span>
                </div>
            </div>
            <el-row :gutter="20" class="docs" v-if="docs.length > 0">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" v-for="(doc, i) in waitingDocs" :key="i">
                    <doc
                        :data="doc"
                        @fetchDocs="getDocuments"
                        @reportDoc="openReportDialog"
                        @fileUpload="getDocuments"
                    ></doc>
                </el-col>
            </el-row>
            <div class="legend">
                <div class="legend-item">
                    <span class="bullet rejected"></span>
                    <span class="text">{{ $t('account.docs.docs-25') }}</span>
                </div>
            </div>
            <el-row :gutter="20" class="docs" v-if="docs.length > 0">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" v-for="(doc, i) in rejectedDocs" :key="i">
                    <doc :data="doc" @reportDoc="openReportDialog" @fileUpload="getDocuments"></doc>
                </el-col>
            </el-row>
            <div class="legend">
                <div class="legend-item">
                    <span class="bullet reported"></span>
                    <span class="text">{{ $t('account.docs.docs-26') }}</span>
                </div>
            </div>
            <el-row :gutter="20" class="docs" v-if="docs.length > 0">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" v-for="(doc, i) in reportedDocs" :key="i">
                    <doc :data="doc" @reportDoc="openReportDialog" @fileUpload="getDocuments"></doc>
                </el-col>
            </el-row>
            <div class="legend">
                <div class="legend-item">
                    <span class="bullet signed"></span>
                    <span class="text">{{ $t('account.docs.docs-27') }}</span>
                </div>
            </div>
            <el-row :gutter="20" class="docs" v-if="docs.length > 0">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" v-for="(doc, i) in signedDocs" :key="i">
                    <doc :data="doc" @reportDoc="openReportDialog" @fileUpload="getDocuments"></doc>
                </el-col>
            </el-row>
            <div class="legend">
                <div class="legend-item">
                    <span class="bullet canceled"></span>
                    <span class="text">{{ $t('account.docs.docs-28') }}</span>
                </div>
            </div>
            <el-row :gutter="20" class="docs" v-if="docs.length > 0">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" v-for="(doc, i) in canceledDocs" :key="i">
                    <doc :data="doc" @reportDoc="openReportDialog" @fileUpload="getDocuments"></doc>
                </el-col>
            </el-row>
        </div>
        <el-dialog
            :title="$t('account.docs.docs-9')"
            :visible.sync="report.reportDialog"
            width="50%"
            @close="resetReport"
        >
            <el-input type="textarea" :rows="2" :placeholder="$t('account.docs.docs-10')" v-model="report.reportText">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button class="b-c-rd" @click="report.reportDialog = false">{{ $t('general.cancel') }}</el-button>
                <el-button class="b-c-rdp" type="primary" @click="reportDocProblem()">{{
                    $t('general.send')
                }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const Box = () => import('vue-info-box-element');
import { getDocuments, reportDoc } from '@/api/cont';
import { EventBus } from '@/utils/Bus';
const Doc = () => import('./components/doc.single');
export default {
    components: {
        Doc,
        Box,
    },
    data() {
        return {
            docs: [],
            report: {
                reportDialog: false,
                reportedId: 0,
                reportText: '',
            },
        };
    },
    methods: {
        getDocuments() {
            this.docs = [];
            getDocuments()
                .then((res) => {
                    if (res == null || typeof res.message === 'undefined') return false;
                    this.docs = res.message;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getDocuments',
                        },
                    });
                });
        },
        resetReport() {
            this.report = {
                reportDialog: false,
                reportedId: 0,
                reportText: '',
            };
        },
        reportDocProblem() {
            if (this.report.reportedId == 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('account.docs.docs-16'),
                });
                return false;
            }

            if (this.report.reportText === '') {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('account.docs.docs-17'),
                });
                return false;
            }

            if (this.report.reportText.length < 5) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('account.docs.docs-18'),
                });
                return false;
            }

            reportDoc({
                docid: this.report.reportedId,
                text: this.report.reportText,
            })
                .then((res) => {
                    if (typeof res.message !== 'undefined' && res.message.success == 1) {
                        this.resetReport();
                        this.getDocuments();
                    }
                    return false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'reportDoc',
                            params: {
                                docid: this.report.reportedId,
                                text: this.report.reportText,
                            },
                        },
                    });
                });
        },
        isReported(data) {
            return data.reported !== '' && data.reported != null;
        },
        showReportDetails(text) {
            this.$alert(text, this.$t('account.docs.docs-19'), {
                confirmButtonText: this.$t('general.close'),
                callback: (action) => {},
            });
        },
        docStatusDot(doc) {
            if (doc.generated === false) return 'unsigned';
            if (doc.generated.status === -1) return 'canceled';
            if (doc.generated.reported !== '') return 'reported';
            if (doc.generated.status === 1) return 'signed';
            if (doc.generated.status === 2) return 'waiting';

            return 'unsigned';
        },
        docStatusTagColor(doc) {
            if (doc.generated === false) return 'info';
            if (doc.generated.status === -1) return 'canceled';
            if (doc.generated.reported !== '') return 'danger';
            if (doc.generated.status === 1) return 'success';
            if (doc.generated.status === 2) return 'primary';

            return 'info';
        },
        docStatusTagName(doc) {
            if (doc.generated === false) return 'Nesemnat';
            if (doc.generated.reported !== '') return 'Raportat';
            if (doc.generated.status === -1) return 'Anulat';
            if (doc.generated.status === 1) return 'Semnat';
            if (doc.generated.status === 2) return 'In asteptare';

            return 'Nesemnat';
        },
        openReportDialog(id) {
            this.report.reportedId = id;
            this.report.reportDialog = true;
        },
    },
    computed: {
        infoDocs() {
            return this.docs.filter((doc) => doc.type != null && doc.type === 'info');
        },
        unsignedDocs() {
            return this.docs.filter(
                (doc) =>
                    doc.type == null &&
                    (!doc.generated ||
                        (doc.generated &&
                            parseInt(doc.generated.status) === 0 &&
                            doc.generated.reported === '' &&
                            doc.generated.reject_reason == null))
            );
        },
        waitingDocs() {
            return this.docs.filter((doc) => doc.generated && parseInt(doc.generated.status) === 2);
        },
        reportedDocs() {
            return this.docs.filter((doc) => doc.generated && doc.generated.reported !== '');
        },
        rejectedDocs() {
            return this.docs.filter(
                (doc) => doc.generated && parseInt(doc.generated.status) === 0 && doc.generated.reject_reason != null
            );
        },
        signedDocs() {
            return this.docs.filter((doc) => doc.generated && parseInt(doc.generated.status) === 1);
        },
        canceledDocs() {
            return this.docs.filter((doc) => doc.generated && parseInt(doc.generated.status) === -1);
        },
    },
    created() {
        this.getDocuments();
    },
    mounted() {},
    destroyed() {
        EventBus.$off();
    },
};
</script>
<style lang="scss">
@import './src/styles/modules/cont.scss';
</style>
